<template>
  <bg-style v-imloading="loading" :bg="model.background" :class="{ 'mobile': isMobile }" class="sign-activity px-5 py-10" :style="getStyle">
    <div v-if="model.titleVisible" class="sign-activity__title">
      <rich-text v-model="model.title" :editing="editing" :stroke.sync="model.titleStroke" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full title" :disabled="!editing" theme="snow" />
    </div>
    <div v-if="model.subtitleVisible" class="sign-activity__subtitle mt-4">
      <rich-text v-model="model.subtitle" :editing="editing" :stroke.sync="model.subTitleStroke" :disabled="!editing" theme="snow" />
    </div>
    <template v-if="!noData">
      <div class="sign-activity__info flex justify-center text-16 gap-20 pt-10">
        <span>{{ $t('siteBuild.signWidget.tip1') }} {{ signData.has_sign_in_days }}</span>
        <span>{{ $t('siteBuild.signWidget.tip2') }} {{ signData.remain_appending_days }}</span>
      </div>
      <div ref="content" class="sign-activity__list pt-6">
        <bg-style v-for="(item, index) in signData.sign_in_list" :key="index" :ref="`item-${index}`" class="item" :bg="model.signBackground" @click.native="handleShowDetail(item)">
          <bg-style class="thumb" :bg="model.logoBackground">
            <img class="relative" :src="item.thumb" alt="icon">
          </bg-style>
          <div class="info">
            <div class="name">{{ $t('siteBuild.signWidget.day', { day: item.day_no }) }}</div>
            <div class="details mt-[9px] gap-4">
              <div v-for="(gift, gIndex) in item.gift_list" :key="gIndex" class="pack flex items-center flex-shrink-0">
                <img class="w-[40px] h-[40px]" :src="gift.gift_goods_logo" alt="" />
                <i class="el-icon-close" />
                <span class="number">{{ gift.num }}</span>
              </div>
            </div>
            <bg-style v-if="(editing && index === 0) || item.is_sign_in" class="handle" :bg="model.doneBackground">
              <span class="relative">{{ $t('siteBuild.signWidget.signed') }}</span>
            </bg-style>
            <bg-style v-else-if="(editing && index === 1) || item.is_appending" class="handle" :bg="model.repairBackground"  @click.native.stop="handleSignIn(item)">
              <span class="relative">{{ $t('siteBuild.signWidget.repair') }}</span>
            </bg-style>
            <bg-style v-else-if="(editing && index === 2) || item.is_available_sign_in" class="handle" :bg="model.buttonBackground" @click.native.stop="handleSignIn(item)">
              <span class="relative">{{ $t('siteBuild.signWidget.sign') }}</span>
            </bg-style>
            <bg-style v-else class="handle" :bg="model.doneBackground">
              <span class="relative">{{ $t('siteBuild.signWidget.sign') }}</span>
            </bg-style>
          </div>
        </bg-style>
      </div>
      <template v-if="signData.consecutive_list.length">
        <rich-text v-model="model.secondTitle" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[30px]'" class="text-20 relative" />
        <rich-text v-model="model.secondSubtitle" :editing="editing" class="mt-4 relative" />
        <div class="sign-activity__list pt-6" :class="{ 'justify-center': signData.consecutive_list.length <= 5 && !isMobile }">
          <bg-style v-for="(item, index) in signData.consecutive_list" :key="index" :bg="model.signBackground" class="item" @click.native="handleShowDetail(item)">
            <bg-style class="thumb" :bg="model.logoBackground">
              <img class="relative" :src="item.thumb" alt="icon">
            </bg-style>
            <div class="info">
              <div class="name">{{ $t('siteBuild.signWidget.day', { day: item.sign_in_consecutive_days }) }}</div>
              <div class="details mt-[9px] gap-4">
                <div v-for="(gift, gIndex) in item.gift_list" :key="gIndex" class="pack flex items-center flex-shrink-0">
                  <img class="w-[40px] h-[40px]" :src="gift.gift_goods_logo" alt="" />
                  <i class="el-icon-close" />
                  <span class="number">{{ gift.num }}</span>
                </div>
              </div>
              <bg-style v-if="(editing && index === 0) || item.is_sign_in" class="handle" :bg="model.doneBackground">
                <span class="relative">{{ $t('siteBuild.activity.haveGet') }}</span>
              </bg-style>
              <bg-style v-else-if="(editing && index === 1) || item.is_get" class="handle" :bg="model.buttonBackground" @click.native.stop="handleGetGift(item)">
                <span class="relative">{{ $t('siteBuild.activity.get') }}</span>
              </bg-style>
              <bg-style v-else class="handle" :bg="model.doneBackground">
                <span class="relative">{{ $t('siteBuild.activity.get') }}</span>
              </bg-style>
            </div>
          </bg-style>
        </div>
      </template>
    </template>
    <el-empty v-else class="relative" :description="$t('siteBuild.noActivity')" />
    <sign-info ref="info" :data="currentRow" :custom-style="getStyle" :is-mobile="isMobile" />
  </bg-style>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment/moment"
import {Message, Empty} from "element-ui";
import isNil from "lodash.isnil";
import tinycolor from "tinycolor2";
import SignInfo from './info.vue'
import RichText from '~/components/richText/index.vue'
import {DeviceEnum} from '~/enums/deviceEnum'
import Bus from "~/site/model/bus";
import {getTextColor} from "~/utils";

export default {
  name: 'SignActivityWidget',
  components: {
    RichText,
    SignInfo,
    [Empty.name]: Empty
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      loading: false,
      activityData: {
        biz_id: null,
        start_time: 0
      },
      noData: false,
      currentRow: {},
      signData: {
        remain_appending_days: 0,
        has_sign_in_days: 0,
        consecutive_list: [],
        sign_in_list: []
      }
    }
  },
  computed: {
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
      siteId: (state) => state.project.info.id
    }),
    getStyle() {
      const textColor = getTextColor(this.model.textColor).color
      const color = tinycolor(textColor)
      color.setAlpha(0.5)
      return {
        '--sign-text-color': textColor,
        '--sign-text-color-50': color.toRgbString(),
        '--sign-button-color': getTextColor(this.model.buttonColor).color
      }
    }
  },
  beforeDestroy() {
    Bus.$off('reloadActivity')
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getActivityData()
      await this.getSingData()
      await this.locationAvailableItem()
      Bus.$on('reloadActivity', async () => {
        this.resetScroll()
        await this.getActivityData()
        await this.getSingData()
        await this.locationAvailableItem()
      })
    },
    resetScroll() {
      this.$refs.content.scrollTo({
        left: 0,
        behavior: 'instant'
      })
    },
    async locationAvailableItem() {
      await this.$nextTick()
      const signIndex = this.signData.sign_in_list.findIndex(item => item.is_available_sign_in)
      const appendIndex = this.signData.sign_in_list.findIndex(item => item.is_appending)
      const noFound = signIndex === -1 && appendIndex === -1
      if (!noFound) {
        const index = signIndex === -1 ? appendIndex : signIndex
        const offsetLeft = this.$refs[`item-${index}`][0].$el.offsetLeft
        this.$refs.content.scrollTo({
          left: offsetLeft,
          behavior: 'instant'
        })
      }
    },
    checkUser() {
      let result = true
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        result = false
      } else if (this.editing) {
        result = false
      }
      return result
    },
    async handleGetGift({ sign_in_consecutive_days, is_get, is_sign_in  }) {
      if (!is_get || is_sign_in) return
      if (!this.checkUser()) return
      const params = {
        activity_id: this.activityData.biz_id,
        sign_in_type: 3,
        consecutive_day: sign_in_consecutive_days,
        site_id: this.siteId
      }
      const [err] = await this.$utils.to(this.$api.activity.signIn(params))
      if (!err) {
        Message.success('Success')
        await this.getSingData()
      } else {
        Message.error(err)
      }
    },
    async handleSignIn({ is_appending, day_no, is_available_sign_in }) {
      if (!this.checkUser()) return
      if (!is_available_sign_in && !is_appending) return
      const params = {
        activity_id: this.activityData.biz_id,
        sign_in_type: 1,
        site_id: this.siteId
      }
      if (is_appending) {
        params.sign_in_type = 2
        params.appending_date = moment(this.activityData.start_time * 1000).add(day_no, 'days').utc().format('YYYY-MM-DD')
        params.day_no = Number(day_no)
      }
      this.loading = true
      const [err] = await this.$utils.to(this.$api.activity.signIn(params))
      this.loading = false
      if (!err) {
        Message.success('Success')
        await this.getSingData()
      } else {
        Message.error(err)
      }
    },
    async getSingData() {
      if (!this.userInfo || !this.activityData.biz_id) return
      const params = {
        activity_id: this.activityData.biz_id,
        page_size: 365,
        site_id: this.siteId,
        page_no: 1
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.activity.querySignActivity(params))
      this.loading = false
      if (!err) {
        this.signData = res
        this.formatData()
      }
    },
    formatData() {
      this.signData.sign_in_list = this.signData.sign_in_list.map(item => {
        const newItem = {...item}
        newItem.thumb = newItem.gift_list[0]?.gift_goods_logo
        return newItem
      })
      this.signData.consecutive_list = this.signData.consecutive_list.map(item => {
        const newItem = {...item}
        newItem.thumb = newItem.gift_list[0]?.gift_goods_logo
        return newItem
      })
      this.noData = this.signData.sign_in_list.length === 0
    },
    clearSignData() {
      this.signData.has_sign_in_days = 0
      this.signData.remain_appending_days = 0
    },
    async getActivityData() {
      this.loading = true
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const params = {
        now_time: now_stem,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || '',
        activity_type: 4,
        status: 2
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params, {editing: this.editing}))
      this.loading = false
      if (!err) {
        this.clearSignData()
        this.activityData = res.list ? res.list[0] : { activity_rule: '' }
        this.activityData.activity_rule = JSON.parse(this.activityData.activity_rule || '{}')
        const { gift_list, consecutive_gift_list } = this.activityData.activity_rule
        this.signData.sign_in_list = gift_list || (this.editing ? this.model.__mockData.sign_in_list : [])
        this.signData.consecutive_list = consecutive_gift_list || (this.editing ? this.model.__mockData.consecutive_list : [])
        this.formatData()
      }
    },
    handleShowDetail(row) {
      if (this.editing || !this.checkUser()) return
      this.currentRow = row
      this.$refs.info.init()
    }
  }
}
</script>

<style lang="less">
.sign-activity {
  &__title, &__subtitle {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
  }
  &__info {
    position: relative;
    color: var(--sign-text-color);
  }
  &__list {
    display: flex;
    max-width: 1210px;
    margin: 0 auto;
    position: relative;
    gap: 12px;
    overflow-x: auto;
    padding-bottom: 20px;
    .item {
      width: 160px;
      flex-shrink: 0;
      box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.08);
      transition: all 0.2s ease;
      &:first-of-type {
        margin-left: 8px;
      }
      &:last-of-type {
        margin-right: 10px;
      }
      &:hover {
        transform: scale(1.05);
      }
      .thumb {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .info {
        padding-bottom: 16px;
        position: relative;
        .details {
          display: flex;
          width: fit-content;
          margin: 0 auto;
          padding-top: 10px;
          overflow: auto;
          max-width: 120px;
          &::-webkit-scrollbar {
            height: 0;
          }
        }
        .name {
          text-align: center;
          padding-top: 12px;
          font-size: 16px;
          color: var(--sign-text-color);
        }
        .el-icon-close {
          color: var(--sign-text-color-50);
        }
        .number {
          color: var(--sign-text-color-50);
          font-size: 12px;
        }
        .handle {
          text-align: center;
          line-height: 32px;
          margin: 16px 16px 0 16px;
          cursor: pointer;
          font-size: 14px;
          color: var(--sign-button-color);
        }
      }
    }
  }
  &.mobile {
    .sign-activity__list {
      gap: 8px;
      padding-left: 4px;
      .item {
        width: 108px;
        transition: none;
        &:hover {
          transform: none;
        }
        &:first-of-type {
          margin-left: 0;
        }
        .details {
          max-width: 90px;
          .el-icon-close {
            font-size: 10px;
          }
        }
        .thumb {
          height: 80px;
          img {
            max-width: 80px;
            max-height: 80px;
          }
        }
        .name {
          font-size: 10px;
        }
        .number {
          font-size: 8px;
        }
        .handle {
          line-height: 22px;
          margin: 10px 10px 0 10px;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
