<template>
  <el-empty v-if="disabled" :description="$t('siteBuild.noActivity')" class="relative"/>
  <div v-else :class="{ 'is-mobile': isMobile }" :style="getStyle" class="accumulation-body relative mt-10">
    <div class="w-full flex justify-between accumulation-body__header">
      <div class="flex items-center">
        <img v-if="activityData.virtual_currency_icon" :src="activityData.virtual_currency_icon" alt class="w-[50px] mr-3"/>
        <div>
          <span>{{ $t('siteBuild.activity.my') }}</span>
          <span v-if="activityData.add_credit_type === 2">{{ activityData.country_currency }}</span>：
        </div>
        <span>{{ currentNumber }}</span>
      </div>
      <span class="flex items-center time">{{ sTime }}</span>
    </div>
    <div ref="content" class="accumulation-body__content">
      <template v-if="controlVisible">
        <div :class="{ 'is-disabled': prevDisabled }" class="prev control-btn" @click="handlePrev">
          <i class="el-icon-arrow-left"/>
        </div>
        <div :class="{ 'is-disabled': nextDisabled }" class="next control-btn" @click="handleNext">
          <i class="el-icon-arrow-right"/>
        </div>
      </template>
      <div :style="scrollStyle" class="scroll-list">
        <bg-style :bg="model.progressBackground" class="progress">
          <bg-style :bg="model.innerBackground" :style="{ width: progressWidth }" class="inner relative h-full"/>
        </bg-style>
        <div v-for="(item, index) in list" :key="index" :ref="`item-${index}`" :class="{ 'is-active': item.active }"
             class="item">
          <div class="circle z-10 relative"/>
          <div class="number">{{ item.virtual_currency_val }}</div>
          <bg-style :bg="model.cardBackground" class="w-full card mt-4" @click.native.stop="handleItemClick(item)">
            <bg-style :bg="model.logoBackground" class="thumb relative">
              <img :src="item.thumb" alt="" class="relative"/>
            </bg-style>
            <div class="info-area relative">
              <div class="mx-auto w-full overflow-auto">
                <div class="gifts gap-2">
                  <div v-for="(gift, giftIndex) in item.gift_list" :key="giftIndex" class="pack flex items-center flex-shrink-0">
                    <img :src="gift.gift_goods_logo" alt="" class="w-[40px] h-[40px]"/>
                    <i class="el-icon-close"/>
                    <span class="text-12">{{ gift.num }}</span>
                  </div>
                </div>
              </div>
              <bg-style v-if="item.level_status === 3" :bg="model.disabledBackground" class="handle mt-[14px]" @click.native.stop>
                <span class="relative">{{ $t('siteBuild.activity.haveGet') }}</span>
              </bg-style>
              <bg-style v-if="item.level_status === 2" :bg="model.getBackground" class="handle mt-[14px]" @click.native.stop="handlePick(item)">
                <span class="relative">{{ $t('siteBuild.activity.get') }}</span>
              </bg-style>
              <bg-style v-if="item.level_status === 1" :bg="model.disabledBackground" class="handle mt-[14px]"
                        @click.native.stop>
                <span class="relative">{{ $t('siteBuild.activity.notQualified') }}</span>
              </bg-style>
            </div>
          </bg-style>
        </div>
      </div>
    </div>
    <info-detail ref="info" :data="currentRow" :is-mobile="isMobile"/>
  </div>
</template>

<script>
import isNil from "lodash.isnil";
import {Message, Empty} from "element-ui";
import moment from "moment/moment";
import {mapState} from "vuex";
import cloneDeep from "lodash.clonedeep";
import InfoDetail from './info.vue'
import tools, {formatZoneTime, getTextColor} from "~/utils";
import Bus from "~/site/model/bus";

import { isBuildWebSite } from "~/config";
const isPreviewMode = !isBuildWebSite

export default {
  name: 'AccumulationWidgetContent',
  components: {
    [Empty.name]: Empty,
    InfoDetail
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      scrollNumber: 0,
      currentNumber: 0,
      list: [],
      loading: false,
      activityData: {},
      sTime: '00:00:00',
      disabled: false,
      currentRow: {},
      progressWidth: 0,
      viewNumber: 7
    }
  },
  computed: {
    getStyle() {
      return {
        '--text-color': getTextColor(this.model.textColor).color,
        '--button-text-color': getTextColor(this.model.buttonTextColor).color,
        '--circle-background': getTextColor(this.model.progressBackground).color,
        '--progress-inner-color': getTextColor(this.model.innerBackground).color
      }
    },
    controlVisible() {
      return this.list.length > this.viewNumber
    },
    scrollStyle() {
      const left = 172 * this.scrollNumber
      return {
        transform: `translateX(-${left}px)`
      }
    },
    maxScrollNumber() {
      return this.list.length - this.viewNumber
    },
    prevDisabled() {
      return this.scrollNumber === 0
    },
    nextDisabled() {
      return this.scrollNumber === this.maxScrollNumber
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    })
  },
  watch: {
    isMobile() {
      const timer = setTimeout(() => {
        this.generateProgress()
        this.resetScroll()
        clearTimeout(timer)
      }, 500)
    }
  },
  mounted() {
    this.init()
    Bus.$on('reloadActivity', async () => {
      this.resetScroll()
      await this.init()
    })
  },
  destroyed() {
    Bus.$off('reloadActivity')
  },
  methods: {
    async init() {
      this.getViewNumber()
      await this.getActivityData()
      this.locationAvailableItem()
    },
    resetScroll() {
      this.scrollNumber = 0
      this.$refs.content.scrollTo({
        left: 0,
        behavior: 'instant'
      })
    },
    activityUnusual() {
      if (this.editing) {
        this.currentNumber = 40
        this.list = cloneDeep(this.model.__mockData)
        this.generateProgress()
      } else {
        this.disabled = true
      }
    },
    handlePrev() {
      const number = this.scrollNumber - 1
      if (number >= 0) {
        this.scrollNumber -= 1
      }
    },
    handleNext() {
      const number = this.scrollNumber + 1
      if (number <= this.maxScrollNumber) {
        this.scrollNumber += 1
      }
    },
    handleItemClick(data) {
      if (this.editing) return
      this.currentRow = data
      this.$refs.info.init()
    },
    async handlePick(item) {
      if (this.editing || this.loading) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
      } else {
        this.loading = true
        const device = this.isMobile ? 'mobile' : 'desktop'
        const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
        const return_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        const gift_items = item.gift_list.map(i => {
          i.logo = i.gift_goods_logo
          return i
        })
        const params = {
          project_id: this.projectId || this.userInfo.project_id || '',
          merchant_id: this.userInfo.merchant_id || '',
          gift_items,
          activity_id: this.activityData.biz_id,
          player_id: (this.userInfo && this.userInfo.uid || '').trim(),
          level_virtual_currency_val: item.virtual_currency_val,
          from_preview_mode: isPreviewMode,
          settings: {
            device,
            language: lang,
            show_order: false,
            return_url
          }
        }
        const [err] = await this.$utils.to(this.$api.activity.pickActivityItems(params))
        this.loading = false
        if (!err) {
          Message.success('Success')
          await this.getActivityData()
          await this.generateProgress()
        } else {
          Message.error(err)
        }
      }
    },
    getViewNumber() {
      this.viewNumber = Math.floor(this.$refs.content.offsetWidth / 172)
    },
    async generateProgress() {
      await this.$nextTick()
      const { list, currentNumber } = this
      const activeArr = []
      const pLeft = this.isMobile ? 0 : 12
      const halfWidth = this.isMobile ? 108 / 2 : 160 / 2
      const areaWidth = this.isMobile ? 98 : 150
      let nearItem
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (currentNumber >= item.virtual_currency_val) {
          activeArr.push(item)
        }
        if (item.virtual_currency_val > currentNumber) {
          nearItem = cloneDeep(item)
          break
        }
      }
      const isMax = list[list.length - 1]?.active
      const lastActive = activeArr[activeArr.length - 1]
      const isHit = currentNumber === lastActive?.virtual_currency_val
      if (isMax) {
        this.progressWidth = '100%'
      } else if (isHit) {
        const $lastActive = this.$refs[`item-${lastActive.index}`][0]
        this.progressWidth = ($lastActive.offsetLeft + halfWidth - pLeft) + 'px'
      } else if (nearItem && lastActive) {
        const $lastActive = this.$refs[`item-${lastActive.index}`][0]
        const gapNumber = nearItem.virtual_currency_val - lastActive.virtual_currency_val
        const averageWidth = areaWidth / gapNumber
        const repairWidth = averageWidth * (currentNumber - lastActive.virtual_currency_val)
        const lastOffset = $lastActive.offsetLeft + halfWidth - pLeft
        this.progressWidth = (lastOffset + repairWidth + 12) + 'px'
      } else {
        const percent = currentNumber / list[0]?.virtual_currency_val
        this.progressWidth = (halfWidth * percent)+ 'px'
      }
    },
    async getActivityData() {
      this.loading = true
      const params = {
        now_time: Math.floor(moment().utc().valueOf() / 1000),
        project_id: this.projectId || this.userInfo.project_id || ''
      }
      const [err,res] = await this.$utils.to(this.$api.activity.getActivityItems(params, {editing: this.editing}))
      this.loading = false
      if (!err) {
        this.activityData = res
        this.currentNumber = res.virtual_currency_num
        this.list = (res.activity_level_hit_list || []).map((item, index) => {
          const newItem = {...item}
          newItem.thumb = item.gift_list[0].gift_goods_logo
          newItem.index = index
          newItem.active = this.currentNumber >= item.virtual_currency_val
          return newItem
        })
        this.countTime()
        await this.generateProgress()
      } else {
        this.activityUnusual()
      }
    },
    locationAvailableItem() {
      const nextIndex = this.list.findIndex(item => item.level_status === 2)
      const noFound = nextIndex === -1
      const current = nextIndex + 1
      const isNoView = (current > this.viewNumber)
      if (!isNoView || noFound) return
      const gapNumber = current - this.viewNumber
      if (this.isMobile) {
        const offsetLeft = this.$refs[`item-${nextIndex}`][0].offsetLeft
        this.$refs.content.scrollTo({
          left: offsetLeft,
          behavior: 'instant'
        })
      } else {
        this.scrollNumber = gapNumber
      }
    },
    countTime() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.activityData.activity_stop_time * 1000).value
      const leftTime = end - now;
      let d = 0;
      let h = 0;
      let m = 0;
      let s= 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        m = Math.floor(leftTime / 1000 / 60 % 60)
        s = Math.floor(leftTime / 1000 % 60)
      }
      this.sTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) {
        setTimeout(this.countTime, 1000);
      } else {
        this.activityUnusual()
      }
    },
  }
}
</script>

<style lang="less">
.accumulation-body {
  &__header {
    color: var(--text-color);
    font-size: 20px;
  }

  &__content {
    width: 100%;
    margin-top: 24px;
    overflow-x: hidden;
    padding-bottom: 20px;
    position: relative;

    .scroll-list {
      display: flex;
      gap: 12px;
      width: max-content;
      padding-left: 12px;
      transition: all 0.36s ease;
      position: relative;

      .progress {
        position: absolute !important;
        width: calc(100% - 12px);
        left: 12px;
        height: 4px;
        top: 8px;
      }

      .item {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        flex-shrink: 0;

        &:last-of-type {
          .progress {
            width: 100%;
          }
        }

        .circle {
          width: 20px;
          height: 20px;
          background-color: var(--circle-background);
          border-radius: 100%;
          margin-bottom: 12px;
          border: 4px solid transparent;
        }

        .number {
          font-size: 24px;
          color: var(--circle-background);
        }

        .card {
          box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.08);
          cursor: pointer;

          &:hover {
            .thumb img {
              transform: scale(1.15);
            }
          }

          .thumb {
            width: 100%;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
              max-height: 100%;
              transition: all 0.24s ease;
            }
          }

          .day {
            color: var(--text-color);
          }

          .info-area {
            width: 100%;
            padding: 0 16px 16px 16px;

            .gifts {
              display: flex;
              margin: 0 auto;
              padding-top: 10px;
              overflow: auto;
              max-width: 100%;
              white-space: nowrap;
              color: var(--text-color);
              flex-wrap: nowrap;
              .pack {
                &:first-of-type {
                  margin: 0 auto;
                }
              }
              &::-webkit-scrollbar {
                height: 0;
              }
            }

            .handle {
              line-height: 32px;
              width: 100%;
              color: var(--button-text-color);

              span {
                text-align: center;
                width: 100%;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }

        &.is-active {
          div.circle {
            background-color: white;
            border-color: var(--progress-inner-color);
          }
          .number {
            color: var(--text-color);
          }
        }
      }
    }

    .control-btn {
      position: absolute;
      width: 54px;
      height: 54px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      top: 175px;
      z-index: 10;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      display: flex;
      transition: all 0.24s ease;
      opacity: 0;
      pointer-events: none;

      i {
        font-size: 24px;
        color: white;
        font-weight: bold;
      }

      &.prev {
        left: 12px;
      }

      &.next {
        right: 8px;
      }

      &.is-disabled {
        opacity: 0 !important;
        pointer-events: none;
        cursor: default;
      }

      &:hover {
        background-color: white;
        box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.08);

        i {
          color: black;
        }
      }
    }

    &:hover {
      .control-btn {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.is-mobile {
    .accumulation-body__header {
      font-size: 14px;
      .time {
        margin-right: 16px;
      }
    }

    .accumulation-body__content {
      overflow-x: auto;

      .control-btn {
        display: none !important;
      }

      .scroll-list {
        //padding-left: 5px;
        //padding-right: 12px;
        padding-left: 0;
        .progress {
          width: 100%;
          height: 3px;
          top: 5px;
          left: 0;
          //left: 5px;
        }
      }

      .item {
        width: 108px;

        .thumb {
          height: 81px;
        }

        .circle {
          height: 12px;
          width: 12px;
          border: 2px solid transparent;
          margin-bottom: 5px;
        }

        .number {
          font-size: 16px;
        }

        .info-area {
          padding: 0 10px 10px 10px;

          .handle {
            line-height: 24px;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
